import Swal from "sweetalert2";
import api from "@/lib/api";

const popToast = {
  methods: {
    log(str1, str2, data = "") {
      // console.log("%c" + str1, "background: #222; color: #bada55");
      console.log(
        `%c${str1.split(":")[0]} | %c${str2}`,
        `background: #222; color: ${str1.split(":")[1]}`,
        `color:#fff`,
        data
      );
    },
    testErrors() {
      api
        .get("/api/error")
        .then((response) => {
          console.log(response);
          // response.data.data
          this.success("Success", "Work in progress.");
        })
        .catch((error) => {
          console.log(error.response);
          this.error("Warning", error.response.data.error);
        });
    },
    error(text, description) {
      // console.log("This is an error");
      this.$moshaToast(
        { title: text, description: description },
        {
          type: "error",
          timeout: 3000,
          showIcon: true,
          toastBackgroundColor: "#dc3545",
        }
      );
    },
    warn(text, description) {
      // console.log("This is a warning");
      this.$moshaToast(
        { title: text, description: description },
        {
          type: "error",
          timeout: 3000,
          showIcon: true,
          toastBackgroundColor: "#ffc107",
        }
      );
    },
    success(text, description) {
      // console.log("This is a success");
      this.$moshaToast(
        { title: text, description: description },
        {
          type: "success",
          timeout: 2000,
          showIcon: true,
          toastBackgroundColor: "#28a745",
        }
      );
    },
    successSwal(message = "Your details have been saved.") {
      Swal.fire({
        title: "Success!",
        text: message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500,
      });
    },
    errorSwal(message = "There was an error, please try again.") {
      Swal.fire({
        title: "Error?",
        text: message,
        icon: "error",
        showConfirmButton: true,
        confirmButtonColor: "#218838",
        timer: 1500,
      });
    },
    cancelledSwal(message = "No details have been changed.") {
      Swal.fire({
        title: "Cancelled?",
        text: message,
        icon: "error",
        showConfirmButton: false,
        timer: 1500,
      });
    },
  },
};

export default popToast;
