<template>
  <svg
    class="logo-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 522.95"
    style="opacity: 1; visibility: inherit; transform: translate(0px, 0px)"
  >
    <path
      d="M60.05,134.73c32.69,83.1,145.56,77,164.1,157.74.75,3.29.78,3.47,1.14,5.33C220.13,161.07,145,96.14,108.1,70.39c-1.67-1.17-1.92-.61,0,0C172.48,89.42,221.5,176.67,223.27,206.13,223.37,143.23,239.68-53.6.14,13.91,40,34.88,38.58,80.16,60.05,134.73Z"
      style="fill: rgb(152, 148, 0)"
    ></path>
    <path
      d="M499.39,284.38c-17.06,91.27-58.4,118.33-109,123q-3,.29-5.91.51a209.77,209.77,0,0,1-25.22,1.48c-27.5,0-52.07-4.6-73-13.66l-.44-.2c-17.5-7.63-31.37-17.26-41.34-28.7-1.38-1.66-2.71-3.35-3.94-5.09a111.24,111.24,0,0,1-16.68-36.45c-3.07-11.64-5.16-23.53-9.46-34.83a65.12,65.12,0,0,0-3.44-7.84C177.44,225.72,83.49,219.28,56,144.05,35.56,88,33.43,40.21,0,19.62V204.06c0,94.85,1.73,115.09,5.19,144.68S19.9,406.47,39,433.12,86.72,481.41,125.14,498,207.87,523,258.07,523q68,0,118.12-21t80.74-61.79c26-34.54,37.91-73.95,41.33-116.63,1.13-14.1,1.66-28.27,1.74-42.41C499.82,282.12,499.39,284.38,499.39,284.38Z"
      style="fill: rgb(61, 68, 29)"
    ></path>
    <path
      d="M499.39,284.38c-17.06,91.27-58.4,118.33-109,123q-3,.29-5.91.51a209.77,209.77,0,0,1-25.22,1.48c-27.5,0-52.07-4.6-73-13.66l-.44-.2c-17.5-7.63-31.37-17.26-41.34-28.7-1.38-1.66-2.71-3.35-3.94-5.09a111.24,111.24,0,0,1-16.68-36.45c-3.07-11.64-5.16-23.53-9.46-34.83a65.12,65.12,0,0,0-3.44-7.84C177.44,225.72,83.49,219.28,56,144.05,35.56,88,33.43,40.21,0,19.62V204.06c0,94.85,1.73,115.09,5.19,144.68S19.9,406.47,39,433.12,86.72,481.41,125.14,498,207.87,523,258.07,523q68,0,118.12-21t80.74-61.79c26-34.54,37.91-73.95,41.33-116.63,1.13-14.1,1.66-28.27,1.74-42.41C499.82,282.12,499.39,284.38,499.39,284.38Z"
      style="fill: rgb(61, 68, 29); opacity: 0.4; mix-blend-mode: multiply"
    ></path>
    <path
      d="M337.06,285.2a50.83,50.83,0,0,1,42.62-35.61A43.76,43.76,0,0,0,294,245.42,55.66,55.66,0,0,1,337.06,285.2Z"
      style="fill: rgb(158, 50, 35)"
    ></path>
    <path
      d="M386.48,249.26a50.43,50.43,0,0,1,29.89,10.43A50.7,50.7,0,0,1,445.5,222.2a43.78,43.78,0,0,0-82.37-9A50.63,50.63,0,0,1,386.48,249.26Z"
      style="fill: rgb(158, 50, 35)"
    ></path>
    <path
      d="M324.87,283.43c1.51,0,3,.08,4.49.19a48.63,48.63,0,0,0-94.28,12.51,55.5,55.5,0,0,1,44.73,10.33A55.62,55.62,0,0,1,324.87,283.43Z"
      style="fill: rgb(158, 50, 35)"
    ></path>
    <path
      d="M385.58,256.12a43.84,43.84,0,0,0-41.79,30.62,55.94,55.94,0,0,1,32.83,31.89,55.35,55.35,0,0,1,25-6.53,50.85,50.85,0,0,1,23.1-31.86A43.8,43.8,0,0,0,385.58,256.12Z"
      style="fill: rgb(158, 50, 35)"
    ></path>
    <path
      d="M451,279.77a43.82,43.82,0,0,0-42.31,32.6,54.86,54.86,0,0,1,17.22,4.74A55.75,55.75,0,0,1,458.11,362c12.32-12.81,21.71-29,27.87-47.73,1.25-3.81,2.42-7.67,3.54-11.54A43.78,43.78,0,0,0,451,279.77Z"
      style="fill: rgb(158, 50, 35)"
    ></path>
    <path
      d="M324.87,290.47a48.54,48.54,0,0,0-39.73,20.6,55.54,55.54,0,0,1,16.44,35.38,56,56,0,0,1,49.69.16,55.86,55.86,0,0,1,19.27-24.24A48.63,48.63,0,0,0,324.87,290.47Z"
      style="fill: rgb(158, 50, 35)"
    ></path>
    <path
      d="M294.71,350.43a48.61,48.61,0,0,0-66.9-44.79c.66,2.81,1.34,5.61,2.08,8.4A107.34,107.34,0,0,0,246,349.3c1.19,1.68,2.47,3.32,3.81,4.92a99.63,99.63,0,0,0,24.95,20.12A56.65,56.65,0,0,1,294.71,350.43Z"
      style="fill: rgb(158, 50, 35)"
    ></path>
    <path
      d="M402.77,319.09a48.65,48.65,0,0,0-45.35,31.06,56.28,56.28,0,0,1,25.06,44.11c.94-.1,1.89-.19,2.82-.3q2.81-.22,5.71-.49c24-2.23,44.28-11.1,60.37-25.08,0-.23,0-.45,0-.67A48.63,48.63,0,0,0,402.77,319.09Z"
      style="fill: rgb(158, 50, 35)"
    ></path>
    <path
      d="M326.28,348.37A48.65,48.65,0,0,0,281.5,378c2.69,1.37,5.44,2.71,8.33,4l.42.2c20.28,8.76,44.05,13.21,70.66,13.21,4.77,0,9.41-.17,13.95-.47A48.63,48.63,0,0,0,326.28,348.37Z"
      style="fill: rgb(158, 50, 35)"
    ></path>
    <path
      d="M466.16,224.5a43.77,43.77,0,0,0-43.66,40.74,50.88,50.88,0,0,1,8.32,11.83A50.59,50.59,0,0,1,492,293.83c7.37-32.7,7.09-53.19,7.1-53.28C491.05,230.77,479.81,224.5,466.16,224.5Z"
      style="fill: rgb(158, 50, 35)"
    ></path>
    <path
      d="M499.94,174.18V71.1c0-29.88-24.31-54.51-54.19-54.51l-157.68.12V237.26a51,51,0,0,1,68.65-28.33,51.06,51.06,0,0,1,95.67,9.89A50.91,50.91,0,0,1,500,229v-54.8Z"
      style="fill: rgb(107, 112, 46)"
    ></path>
  </svg>
</template>
