// import { createRouter, createWebHistory } from "vue-router";
import { createRouter, createWebHashHistory } from "vue-router";
// import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Email from "../views/Email.vue";

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/email",
    name: "Email",
    component: Email,
  },
  {
    path: "/download/:product",
    name: "Download",
    component: () => import("../views/Download.vue"),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../views/404.vue"),
  },
  {
    path: "/register/verify/:uid",
    name: "Verify",
    component: () => import("../views/Verify.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/Register.vue"),
  },
  {
    path: "/reset/verify/:uid",
    name: "Reset password",
    component: () => import("../views/Forgot.vue"),
  },
  {
    path: "/register/verify",
    name: "Thanks",
    component: () => import("../views/ThankYou.vue"),
  },
  { path: "/user", redirect: "/user/dashboard" },
  { path: "/user/customer", redirect: "/user/customers" },
  { path: "/user/admin", redirect: "/user/admins" },
  {
    path: "/user/:content/:product/:edit",
    name: "Editor",
    component: () => import("../views/User.vue"),
  },
  {
    path: "/user/:content/:product",
    name: "Product",
    component: () => import("../views/User.vue"),
  },
  {
    path: "/user/:content",
    name: "Dashboard",
    component: () => import("../views/User.vue"),
  },
  {
    path: "/user/customer/:user",
    name: "Customer",
    component: () => import("../views/User.vue"),
  },

  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   alias: ["/account"],
  //   component: () => import("../views/Dashboard.vue"),
  // },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import("../views/404.vue"),
  },
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = "Upstream - " + to.name;
  next();
});

export default router;
