import axios from "axios";

// let baseUrl = `http://localhost:7771`; //window.baseUrl

// console.log("baseUrl", baseUrl);
// axios.defaults.baseURL = baseUrl;
axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";

const api = {
  get(endPoint) {
    return axios.get(`${endPoint}`);
  },
  post(endPoint, data) {
    return axios.post(`${endPoint}`, data);
  },
  files(endPoint, formData, token) {
    return axios.post(`${endPoint}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "X-CSRF-TOKEN": token,
      },
    });
  },
  // baseUrl,
};

export default api;

// axios
//   .get(`http://localhost:7771/hello`, {
//     headers: {
//       "Content-Type": "application/json",
//     },
//   })
//   .then((response) => {
//     // handle success
//     console.log(response);
//     this.title = response.data;
//   })
//   .catch((error) => {
//     // handle error
//     console.log(error);
//   })
//   .then(() => {
//     // always executed
//   });
