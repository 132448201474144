import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import helpers from "@/mixins/helpers";
import moshaToast from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
import Vue3TouchEvents from "vue3-touch-events";

createApp(App)
  .use(router)
  .use(store)
  .use(moshaToast)
  .use(Vue3TouchEvents)
  .mixin(helpers)
  .mount("#app");
