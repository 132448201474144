<template>
  <section class="login-content">
    <div class="logo text-center">
      <LoginLogoIcon />
      <div class="bar">
        <div class="progress"></div>
      </div>
      <LoginLogoName />
    </div>
    <div class="login-box">
      <form class="login-form" v-on:submit="login">
        <h3 class="login-head">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path
              d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"
            ></path></svg
          >SIGN IN
        </h3>
        <div class="form-group">
          <label class="control-label">EMAIL ADDRESS</label
          ><input
            class="form-control"
            type="email"
            placeholder="Email"
            name="email"
            autofocus=""
            v-model="user.email"
          />
        </div>
        <div class="form-group">
          <label class="control-label">PASSWORD</label
          ><input
            class="form-control"
            type="password"
            name="password"
            placeholder="Password"
            v-model="user.password"
          />
        </div>
        <div class="form-group">
          <div class="utility">
            <p class="semibold-text mb-2">
              <a
                class="flip"
                href="#"
                data-toggle="flip"
                @click.prevent="flipLogin"
                >Forgot Password ?</a
              >
            </p>
            <p class="semibold-text mb-2">
              <a class="flip" href="/#/register">Register</a>
            </p>
          </div>
        </div>
        <div class="form-group btn-containerx">
          <button class="btn btn-primary btn-block" type="submit">
            <i class="fa fa-sign-in fa-lg fa-fw"></i>SIGN IN
          </button>
        </div>
      </form>
      <form class="forget-form" v-on:submit="forgot">
        <h3 class="login-head">
          <i class="fa fa-lg fa-fw fa-lock"></i>Forgot Password ?
        </h3>
        <div class="form-group">
          <label class="control-label">EMAIL</label
          ><input
            class="form-control"
            type="text"
            name="email"
            placeholder="Email"
            v-model="user.email"
          />
        </div>
        <div class="form-group btn-container">
          <button class="btn btn-primary btn-block" type="submit">
            <i class="fa fa-unlock fa-lg fa-fw"></i>RESET
          </button>
        </div>
        <div class="form-group mt-3">
          <p class="semibold-text mb-0">
            <a
              class="flip"
              href="#"
              data-toggle="flip"
              @click.prevent="flipLogin"
              ><i class="fa fa-angle-left fa-fw"></i> Back to Login</a
            >
          </p>
        </div>
      </form>
      <div class="overlay overlay--50" v-if="sending">
        <div class="m-loader m-loader--80">
          <svg class="m-circular" viewBox="25 25 50 50">
            <circle
              class="path"
              cx="50"
              cy="50"
              r="20"
              fill="none"
              stroke-width="4"
              stroke-miterlimit="10"
            ></circle>
          </svg>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
// @ is an alias to /src
import router from "@/router";
import api from "@/lib/api";
import { gsap, CSSPlugin } from "gsap/all";
gsap.registerPlugin(CSSPlugin);
import LoginLogoIcon from "@/components/LoginLogoIcon.vue";
import LoginLogoName from "@/components/LoginLogoName.vue";

export default {
  name: "Login",
  components: {
    LoginLogoIcon,
    LoginLogoName,
  },
  data() {
    return {
      user: {
        // email: "jay@fewd.com.au",
        // password: "Zbddecba1",
        email: "",
        password: "",
      },
      csrf: "",
      sending: false,
    };
  },
  beforeCreate() {
    api
      .get("/api/login")
      .then((response) => {
        console.log("res", response);
        this.csrf = response.data.csrfToken;
      })
      .catch((errors) => {
        console.log(errors.response);
        console.log(errors.response.data.error);
      });
  },
  mounted() {
    var tl = gsap.timeline({ delay: 1 });

    tl.from(".logo-icon", { duration: 0.2, scale: 0.6, opacity: 0 });
    tl.to(".logo-icon", { duration: 0.1, scale: 1 });
    tl.to(".logo-icon", { duration: 0.1, scale: 0.9 });
    tl.to(".logo-icon", { duration: 0.3, scale: 1 });
    tl.from(
      ".logo-icon path",
      {
        duration: 0.3,
        fill: "#ccc",
        stagger: 0.1,
      },
      "-=0.4"
    );
    tl.set(".logo-icon", { duration: 0, delay: 1 });
    tl.from(".logo-name", { duration: 0.2, y: 10, opacity: 0 });
    tl.set(".login-box", {
      onComplete: () => {
        document.querySelector(".login-box").classList.add("height");
      },
    });

    tl.from(".bar", { duration: 0.2, opacity: 0 }, 0.7);
    tl.to(".progress", { duration: 1, delay: 0.2, width: "200px" }, 0.9);
    tl.to(".bar", { duration: 0.2, opacity: 0 }, 2.3);
  },
  methods: {
    flipLogin() {
      console.log("flipped");
      document.querySelector(".login-box").classList.toggle("flipped");
    },
    login(e) {
      e.preventDefault();
      this.sending = true;

      let data = {
        email: this.user.email,
        password: this.user.password,
        _csrf: this.csrf,
      };
      api
        .post("/api/login", data)
        .then((response) => {
          console.log("Logged in", response);
          router.push("/user/dashboard");
          this.success("Success", response.data);
        })
        .catch((error) => {
          console.log(error.response);
          this.sending = false;
          this.error("Warning", error.response.data);
        });
    },
    forgot(e) {
      e.preventDefault();
      this.sending = true;

      let data = {
        email: this.user.email,
        _csrf: this.csrf,
      };
      api
        .post("/api/forgot", data)
        .then((response) => {
          console.log("Logged in", response);
          this.sending = false;
          this.success(
            "Success",
            "Please check your email for the reset link."
          );
          router.push("/login");
        })
        .catch((error) => {
          console.log(error.response);
          this.sending = false;
          this.error(
            "Warning",
            "There has been an error please try again later."
          );
        });
    },
  },
};
</script>
