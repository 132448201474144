<template>
  <svg
    class="logo-name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 557.75 125.72"
    style="opacity: 1; visibility: inherit; transform: translate(0px, 0px)"
  >
    <path
      d="M40.41,88.44a.83.83,0,0,1-.34,0,3.06,3.06,0,0,1-.23-.86,7.19,7.19,0,0,0-.28-1.33,1.52,1.52,0,0,0-1.47-1.08,13.27,13.27,0,0,0-5.19,1.75,20,20,0,0,1-8.73,1.57c-7.37,0-13.3-2.07-17.65-6.15S0,72.66,0,65.76A33.74,33.74,0,0,1,.49,60.1l8-44.9c.06-.62.27-.95,1.15-.95H25.33c.47,0,.47.08.47.31V15L17.87,59.81a11.14,11.14,0,0,0-.18,2.3,10.39,10.39,0,0,0,3,7.65,10.61,10.61,0,0,0,7.81,3A14.21,14.21,0,0,0,38.24,69a15.07,15.07,0,0,0,5-8.94l8-44.9c.06-.62.27-.95,1.15-.95H67.91c.48,0,.48.08.48.31V15L55.64,87.48c-.06.63-.27,1-1.15,1Z"
      style="fill: #3d441d"
    ></path>
    <path
      d="M64.6,125.72c-.48,0-.48-.08-.48-.31V125L83.5,15.2c.06-.62.27-.95,1.15-.95H98.73a.83.83,0,0,1,.34.05,3.32,3.32,0,0,1,.23.86c0,.46.11.86.17,1.2a1.36,1.36,0,0,0,1.41,1.2,14.39,14.39,0,0,0,5.34-1.74,21.21,21.21,0,0,1,8.91-1.57c7.25,0,13.14,2.07,17.48,6.15s6.53,9.57,6.53,16.36a34.83,34.83,0,0,1-.49,5.66l-3.14,17.39a37.71,37.71,0,0,1-4.13,11,38.66,38.66,0,0,1-7.87,9.73,29.67,29.67,0,0,1-20.8,7.9A18.09,18.09,0,0,1,98.28,88a19.48,19.48,0,0,1-3.34-1.11c-1-.43-1.85-.82-2.56-1.15a5.18,5.18,0,0,0-2.1-.58c-.7,0-1.93.33-2.33,2.52l-6.62,37.12c-.07.63-.28,1-1.16,1Zm28.17-66a4.45,4.45,0,0,0-.19,1.37v1a10.41,10.41,0,0,0,3,7.65,10.82,10.82,0,0,0,8,3,14.11,14.11,0,0,0,9.5-3.52,15.47,15.47,0,0,0,5.08-9.1l3.16-17.84a11.24,11.24,0,0,0,.17-2.31,10.15,10.15,0,0,0-3-7.47,10.62,10.62,0,0,0-7.81-3,14.42,14.42,0,0,0-9.75,3.61,14.71,14.71,0,0,0-5,8.87Z"
      style="fill: #3d441d"
    ></path>
    <path
      d="M166.17,90.09a30,30,0,0,1-10.39-1.68,24.53,24.53,0,0,1-7.69-4.4,18.76,18.76,0,0,1-4.68-6,14.94,14.94,0,0,1-1.58-6.43c0-.24.41-.44.65-.55l14.76-6.63a1.07,1.07,0,0,1,.48-.09c.15,0,.25,0,.32.43,1.77,6.78,5.52,10.17,11.16,10.17h4.89a13.57,13.57,0,0,0,8.53-2.58c2.29-1.76,3.45-3.8,3.45-6a4.71,4.71,0,0,0-1.85-4.09,12.13,12.13,0,0,0-4.65-1.8c-1.92-.39-4.24-.76-6.89-1.09a60.92,60.92,0,0,1-8.7-1.8c-5.38-1.5-9.37-3.51-11.86-6a13,13,0,0,1-3.66-9.59A27.39,27.39,0,0,1,153,27,28.74,28.74,0,0,1,164.4,16.47a34.43,34.43,0,0,1,16.16-3.88h4.83c7.37,0,13.27,1.83,17.51,5.43a15.71,15.71,0,0,1,2.85,3A30.33,30.33,0,0,1,208,24.58,17,17,0,0,1,209.43,28a10.42,10.42,0,0,1,.46,2.66.86.86,0,0,1-.64.88l-14.77,6.63a2.77,2.77,0,0,1-.64.26,2.16,2.16,0,0,1-.38-.65c-.68-1.36-1.29-3-1.84-4.19a13.7,13.7,0,0,0-2-3.17,9.23,9.23,0,0,0-2.83-2.2,9.91,9.91,0,0,0-4.41-.83h-4.89c-3.54,0-6.31.69-8.25,2.06a6.74,6.74,0,0,0-3.06,5.75c0,1.95.65,3.82,1.95,4.7a14,14,0,0,0,4.61,1.86,63.88,63.88,0,0,0,6.66,1.18,53.3,53.3,0,0,1,8.19,1.79c5.62,1.62,9.78,3.67,12.39,6.1a12.29,12.29,0,0,1,3.81,9.49,27.93,27.93,0,0,1-2.42,11.5,29.65,29.65,0,0,1-6.8,9.5,31.91,31.91,0,0,1-10.38,6.46A36.23,36.23,0,0,1,171,90.09Z"
      style="fill: #3d441d"
    ></path>
    <path
      d="M248.19,90.09a26.28,26.28,0,0,1-10.8-2.49,25.25,25.25,0,0,1-8.73-6.16,21.13,21.13,0,0,1-4.81-14,34.64,34.64,0,0,1,.49-5.66l5.2-29.66v-.58a2,2,0,0,0-2.2-2h-8.65c-.47,0-.47-.07-.47-.31v-.41l1.74-8L243,.39,244,0h8c.47,0,.47.08.47.31V.74l-1.82,10.93v.57a1.93,1.93,0,0,0,2.18,2h10.93c.48,0,.48.08.48.31V15l-2.48,13.58c-.06.63-.27,1-1.15,1H249.68a2.83,2.83,0,0,0-2.8,2.27l-5.17,29.69a15.34,15.34,0,0,0-.17,2.63c0,4.84,3.34,8.13,9.92,9.77.89.23,1.59.4,2.09.51.06,0,.08,0,.08.16V75l-2.47,14.18c-.19.63-.47.71-.57.74A8.93,8.93,0,0,1,248.19,90.09Z"
      style="fill: #3d441d"
    ></path>
    <path
      d="M264.26,88.44c-.48,0-.48-.08-.48-.31V87.7l12.75-72.5c.06-.62.27-.95,1.15-.95h14.08a1,1,0,0,1,.49.08v.49l-.33,2.33v.56a1.86,1.86,0,0,0,2,2h.2l1.23-.62,0,0c4.58-3.2,9-4.82,13.1-4.82a19.56,19.56,0,0,1,6.18.77c1.62.58,1.75,1,1.75,1v.24l-3,15.43c-.14.75-.36,1.13-.65,1.13-.57,0-1.61-.26-3.41-1.51A11,11,0,0,0,303,29.51a13.59,13.59,0,0,0-9.17,3.54,15.57,15.57,0,0,0-4.92,9.24l-8,45.19c-.06.63-.27,1-1.15,1Z"
      style="fill: #3d441d"
    ></path>
    <path
      d="M339.65,90.09c-8,0-14.36-2.07-18.81-6.15s-6.69-9.57-6.69-16.36a40.61,40.61,0,0,1,.49-5.84l3.64-20.52a29.18,29.18,0,0,1,4.27-10.79,40.23,40.23,0,0,1,8.11-9.17,39.51,39.51,0,0,1,10.79-6.32,33.45,33.45,0,0,1,12.28-2.35,27.1,27.1,0,0,1,10.21,1.85,23.36,23.36,0,0,1,7.83,5.11,24,24,0,0,1,5.12,7.6,23.57,23.57,0,0,1,1.84,9.28v2.32a9,9,0,0,1-.3,2.11l-2.34,13c-.06.63-.27,1-1.15,1H335.51a2.85,2.85,0,0,0-2.81,2.27l0,.1a28.8,28.8,0,0,0-.84,6.54,10.39,10.39,0,0,0,3,7.64,10.83,10.83,0,0,0,8,3A12.84,12.84,0,0,0,349,72.63a14.87,14.87,0,0,0,5.09-4.57c1.25-1.69,2.15-3,2.74-3.81s.86-1,1-1H358l13.36,7.18c.23.14.38.3.38.41,0,.31-.23,1.33-2.38,4a67.73,67.73,0,0,1-7.93,7.93C356,87.66,348.67,90.09,339.65,90.09Zm10.77-62.23A14.05,14.05,0,0,0,345,28.91a19.21,19.21,0,0,0-4.51,2.61A13.48,13.48,0,0,0,337.35,35a7.06,7.06,0,0,0-1.2,3.68,2.28,2.28,0,0,0,2.34,2.44h19.72c1.8,0,2.84-1.53,2.84-3.44a9.24,9.24,0,0,0-3-7A10.75,10.75,0,0,0,350.42,27.86Z"
      style="fill: #3d441d"
    ></path>
    <path
      d="M424.82,88.44c-.48,0-.48-.07-.48-.31V87.8c0-2.33-1-2.68-1.68-2.68a13.74,13.74,0,0,0-5.42,1.75,20.59,20.59,0,0,1-8.83,1.57,28.3,28.3,0,0,1-10-1.69,22.13,22.13,0,0,1-7.58-4.63A20.8,20.8,0,0,1,386,75a23.58,23.58,0,0,1-1.76-9.28,34.83,34.83,0,0,1,.49-5.66l3.15-17.39a33.92,33.92,0,0,1,4.28-11.56,35.51,35.51,0,0,1,7.6-9,34.33,34.33,0,0,1,22.07-7.92A17.45,17.45,0,0,1,430,15.8c2.22,1.2,3.75,1.76,4.8,1.76a1.77,1.77,0,0,0,1.67-1,11.76,11.76,0,0,0,.53-1.25,3.49,3.49,0,0,1,.52-.91,1.11,1.11,0,0,1,.76-.19h14.08c.48,0,.48.08.48.31V15l-12.75,72.5c-.06.63-.27,1-1.15,1Zm-22.7-28.69a4.45,4.45,0,0,0-.19,1.37v1a10.41,10.41,0,0,0,3,7.65,10.84,10.84,0,0,0,8,3,14.09,14.09,0,0,0,9.5-3.52,15.54,15.54,0,0,0,5.09-9.1l3.14-17.84A12.21,12.21,0,0,0,430.8,40a10.12,10.12,0,0,0-3-7.57,10.81,10.81,0,0,0-7.8-2.89,14.42,14.42,0,0,0-9.75,3.61,14.7,14.7,0,0,0-5,8.87Z"
      style="fill: #3d441d"
    ></path>
    <path
      d="M532.42,88.44c-.48,0-.48-.08-.48-.31V87.7l7.93-45.37a3.86,3.86,0,0,0,.19-1.2V40a10.67,10.67,0,0,0-2.71-7.44,8.62,8.62,0,0,0-6.59-3,10.49,10.49,0,0,0-4.33,1,13.36,13.36,0,0,0-3.89,2.74,20.29,20.29,0,0,0-3,3.89A15.09,15.09,0,0,0,517.66,42l-7.95,45.52c-.07.63-.27,1-1.16,1H492.81c-.47,0-.47-.08-.47-.31V87.7l7.93-45.37a4.17,4.17,0,0,0,.19-1.2V40a10.67,10.67,0,0,0-2.71-7.44,8.63,8.63,0,0,0-6.59-3,10.18,10.18,0,0,0-4.17,1,14.79,14.79,0,0,0-4,2.73,18.38,18.38,0,0,0-3.12,3.9A15.18,15.18,0,0,0,478.06,42l-7.95,45.52c-.07.63-.27,1-1.16,1H453.38c-.48,0-.48-.08-.48-.31V87.7l12.75-72.5c.06-.62.27-.95,1.15-.95h14.08c.42,0,.48.06.48.47v1.16a1.78,1.78,0,0,0,1.84,2c.59,0,1.76-.19,5.19-1.91a20,20,0,0,1,8.9-1.73,16.4,16.4,0,0,1,8.27,1.95A53.81,53.81,0,0,1,511.35,20a2.57,2.57,0,0,0,1.51.68,3.43,3.43,0,0,0,1.76-.62c2.14-1.17,4.72-2.47,7.64-3.85s6.71-2,11.65-2c7.35,0,13.23,2.09,17.46,6.22s6.38,9.6,6.38,16.29a34.83,34.83,0,0,1-.49,5.66l-8,45.06c-.07.63-.27,1-1.15,1Z"
      style="fill: #3d441d"
    ></path>
  </svg>
</template>
