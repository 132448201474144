<template>
  <section class="email-design" v-html="html"></section>
</template>
<script>
import api from "@/lib/api";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      html: "<h1>Test</h1>",
    };
  },
  beforeCreate() {
    api
      .get("/api/email")
      .then((response) => {
        console.log("res", response);
        this.html = response.data.data;
      })
      .catch((errors) => {
        console.log(errors.response.data.error);
      });
  },
  mounted() {},
  methods: {},
};
</script>
