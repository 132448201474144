import { createStore } from "vuex";
import api from "@/lib/api";

function log(str1, str2, data = "") {
  console.log(
    `%c${str1.split(":")[0]} | %c${str2}`,
    `background: #222; color: ${str1.split(":")[1]}`,
    `color:#fff`,
    data
  );
}

// Create a new store instance.
export default createStore({
  state() {
    return {
      view: "grid",
      shoppingCart: [],
      heartsObjects: [],
      products: [],
      productSingle: {},
      searchResults: [],
      searchTerm: "",
      searchFilter: [],
      user: {},
      csrf: "",
      countries: [],
      filters: [false, false, false, false],
      customers: [],
      selectCustomer: {},
      accountmanager: {},
    };
  },

  getters: {
    getHeartsAsStrings(state) {
      return typeof state.user.hearts !== "undefined"
        ? state.user.hearts.map((x) => x.toString())
        : [];
    },
    getProcesses(state) {
      const processes = state.products
        .map((x) => x.process)
        .filter((v, i, a) => a.indexOf(v) === i);
      return processes;
    },
    getCustomer: (state) => (accountid) => {
      return state.customers.find((x) => x.id === accountid);
    },
  },

  mutations: {
    changeShopView(state, view) {
      state.view = view;
    },
    commitCsrfToken(state, token) {
      state.csrf = token;
    },
    resetUserInStore(state) {
      state.user = {};
      state.csrf = "";
    },
    saveUserInStore(state, data) {
      if (data.admin) state.admin = data.admin;
      state.user = data.user;
      state.csrf = data.csrfToken;
    },
    saveProductsInStore(state, data) {
      state.products = data.products;
      state.searchResults = data.products;
      state.countries = data.countries;
    },
    saveProduct(state, data) {
      state.productSingle = data;
    },
    saveSearchInStore(state, data) {
      state.searchResults = data.results;
      state.searchTerm = data.term;
    },
    saveActiveFilters(state, data) {
      state.filters = data;
    },
    saveFilterInStore(state, data) {
      state.searchFilter = data;
    },
    saveHeartsInStore(state, data) {
      state.hearts = data;
    },
    updateHeartProducts(state, data) {
      state.heartsObjects = data;
    },
    updateCartValues(state, data) {
      state.user.cart[data.index].ordervariations[data.property] = data.value;
    },
    removeItemFromCart(state, data) {
      state.user.cart.splice(data, 1);
    },
    saveCustomersInStore(state, data) {
      state.customers = data.customers;
    },
    saveAdminInStore(state, data) {
      state.accountmanager = data;
    },
  },

  actions: {
    getProduct({ state, commit }, prodSlug) {
      return new Promise((resolve) => {
        const product = state.products.find((x) => {
          if (x.slug === prodSlug) {
            return x;
          }
        });
        commit("saveProduct", product);
        resolve(true);
      });
    },
    sortResults({ state, commit }, data = { sorter: "name", dir: "down" }) {
      return new Promise((resolve) => {
        log("Store:#0f0", "Sort order", data);
        let sort = data.sorter === "category" ? "catagory" : data.sorter; // OOPS... What was wrong here?
        const updatedSearch = state.searchResults.sort((a, b) => {
          return data.dir === "down"
            ? a[sort].localeCompare(b[sort])
            : b[sort].localeCompare(a[sort]);
        });
        // log("Store:#0f0", "Sort order", updatedSearch);
        commit("saveSearchInStore", { results: updatedSearch, term: "" });
        resolve(true);
      });
    },

    getProductsfilter({ state, commit }, data) {
      return new Promise((resolve) => {
        log("Store:#0f0", "Filter page", data);
        log("Store:#0f0", "state.heartsObjects", state.heartsObjects);

        let pageType =
          data.page === "shop" ? state.products : state.heartsObjects;
        log("Store:#0f0", "Filter", pageType);
        let filter = pageType;
        // log("Store:#0f0", "Search filter", state.searchFilter);
        if (state.searchFilter.length > 0) {
          // const cupingCheck = (list) => {
          //   console.log(list);
          //   const found = list.filter((x) =>
          //     state.searchFilter.includes(x.toLowerCase())
          //   );
          //   return found.length > 0;
          // };
          // const metaTags = (list) => {
          //   console.log(list);
          //   const found = list.filter((x) =>
          //     state.searchFilter.includes(x.toLowerCase())
          //   );
          //   return found.length > 0;
          // };

          if (state.filters[0]) {
            filter = filter.filter((x) => {
              // log("Filter", "Search Counrty", x.country);
              // const notes = x.cuppingnotes.split(" • ");
              // const tags = x.metatags.split(":");
              // console.log(notes);
              return state.searchFilter.includes(x.country.toLowerCase());
              // state.searchFilter.includes(x.region.toLowerCase()) ||
              // state.searchFilter.includes(x.process.toLowerCase()) ||
              // state.searchFilter.includes(x.catagory.toLowerCase()) ||
              // state.searchFilter.includes(x.status.toLowerCase()) ||
              // cupingCheck(notes) ||
              // metaTags(tags)
            });
          }

          if (state.filters[1]) {
            filter = filter.filter((x) => {
              // console.log(state.searchFilter, x.catagory);
              return state.searchFilter.includes(x.catagory.toLowerCase());
            });
          }

          if (state.filters[2]) {
            filter = filter.filter((x) => {
              return state.searchFilter.includes(x.process.toLowerCase());
            });
          }

          if (state.filters[3]) {
            filter = filter.filter((x) => {
              return (
                state.searchFilter.includes(x.status) ||
                state.searchFilter.includes(x.feature)
              );
            });
          }

          // filter = filter.map((x) => x);
          // log("Store:#0f0", "Filter", filter);
        }
        // log("Filter", "Searct Filter", filter);

        const shortList = [];

        let regex = new RegExp(data.term, "i");

        const results = filter.filter((x) => {
          // const results = state.products.filter((x) => {
          // console.log(data.term, x.country, x.country === data.term);
          // console.log(x.catagory, regex);

          if (x.name.match(regex)) {
            shortList.push(x.name);
            return true;
          }
          if (x.country.match(regex)) {
            shortList.push(x.country);
            return true;
          }
          if (x.region.match(regex)) {
            shortList.push(x.region);
            return true;
          }
          if (x.producer.match(regex)) {
            shortList.push(x.producer);
            return true;
          }
          if (x.variety.match(regex)) {
            shortList.push(x.variety);
            return true;
          }
          if (x.cuppingnotes.match(regex)) {
            shortList.push(x.cuppingnotes);
            return true;
          }
          if (x.catagory.match(regex)) {
            shortList.push(x.catagory);
            return true;
          }
          // if (x.metatags.match(regex)) {
          //   shortList.push(x.metatags);
          //   return true;
          // }
          if (x.process.match(regex)) {
            shortList.push(x.process);
            return true;
          }
          if (x.altitude.match(regex)) {
            shortList.push(x.altitude);
            return true;
          }
          // if (x.farminformation.match(regex)) {
          //   shortList.push(x.farminformation);
          //   return true;
          // }
          // if (x.status.match(regex)) {
          //   shortList.push(x.status);
          //   return true;
          // }
        });

        commit("saveSearchInStore", { results: results, term: data.term });
        // console.log(state.searchFilter);
        resolve(
          shortList
            .join(" ")
            .replaceAll(/[,|:|.|<p>]/g, " ")
            .split(" ")
            .filter((x) => x.match(regex))
            .filter((v, i, a) => a.indexOf(v) === i)
        );
      });
    },
    updateCsrfToken({ commit }, token) {
      commit("commitCsrfToken", token);
    },
    getUserAPI({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .get("/api/user")
          .then((response) => {
            // console.log("getUserAPI");
            // console.log(response.data.user);
            commit("saveUserInStore", response.data);
            resolve();
          })
          .catch((errors) => {
            console.log(errors);
            reject();
          });
      });
    },
    /*-- --*/
    saveUserAPI({ commit, state }, postData) {
      return new Promise((resolve, reject) => {
        console.log("Mutation", postData);
        // let data = {
        //   ...user,
        //   _csrf: state.csrf,
        // };
        postData.user = state.user;
        postData._csrf = state.csrf;
        api
          .post("/api/account-update", postData)
          .then((response) => {
            console.log("Mutation User update", response.data.user.id);
            commit("saveUserInStore", response.data);
            resolve();
          })
          .catch((errors) => {
            console.log("Cannot update", errors);
            reject();
          });
      });
    },
    /*-- --*/
    // SENDWELCOMEEMAIL(responseid) {
    //   return new Promise((resolve) => {
    //     if (response.data.welcome) {
    //       console.log("DISPATCHING Welcome");
    //       api
    //         .post("/api/account-welcome", {
    //           id: response.data.user.id,
    //           _csrf: state.csrf,
    //         })
    //         .then(() => resolve("OK"));
    //     }
    //   });
    // },
    /*-- --*/
    GETSHOPPRODUCTS({ commit, state }, user) {
      return new Promise((resolve, reject) => {
        // console.log("Action: Shop", user);
        let data = {
          ...user,
          _csrf: state.csrf,
        };
        api
          .get("/api/shop", data)
          .then((response) => {
            // console.log("Mutation User update", response);
            commit("saveProductsInStore", response.data);
            resolve();
          })
          .catch((error) => {
            console.log(error.response);
            reject();
          });
      });
    },
    /*-- --*/
    UPDATEHEARTSINDB({ commit, state }, idUpdate) {
      return new Promise((resolve, reject) => {
        let data = {
          heartId: idUpdate,
          ...state.user,
          _csrf: state.csrf,
        };
        api
          .post("/api/heart-product", data)
          .then((response) => {
            console.log(response);
            commit("saveUserInStore", response.data);
            resolve();
          })
          .catch((error) => {
            console.log(error.response);
            reject();
          });
      });
    },
    GETHEARTSINDB({ commit, state }) {
      return new Promise((resolve, reject) => {
        let data = {
          ...state.user,
          _csrf: state.csrf,
        };
        api
          .post("/api/heart-product-list", data)
          .then((response) => {
            // log("Store:#0f0", "Updated hearts", response);
            commit("updateHeartProducts", response.data);
            resolve();
          })
          .catch((error) => {
            console.log(error.response);
            reject();
          });
      });
    },
    UPDATECARTINDB({ commit, state }, update) {
      return new Promise((resolve, reject) => {
        let data = {
          ...update,
          ...state.user,
          _csrf: state.csrf,
        };
        log("Store:#0f0", "Cart data", update);
        // TODO: Seperate Add and Remove
        api
          .post("/api/add-cart", data)
          .then((response) => {
            console.log(response);
            commit("saveUserInStore", response.data);
            resolve(update);
          })
          .catch((error) => {
            console.log(error.response);
            reject();
          });
      });
    },
    UPDATECARTINORDER({ commit, state }, update) {
      return new Promise((resolve, reject) => {
        commit("updateCartValues", update);
        let data = {
          // ...update,
          ...state.user,
          _csrf: state.csrf,
        };
        api
          .post("/api/update-cart", data)
          .then((response) => {
            console.log(response);
            commit("saveUserInStore", response.data);
            resolve();
          })
          .catch((error) => {
            console.log(error.response);
            reject();
          });
      });
    },
    REMOVEFROMCARTINDB({ commit, state }, index) {
      return new Promise((resolve, reject) => {
        commit("removeItemFromCart", index);
        let data = {
          // ...update,
          ...state.user,
          _csrf: state.csrf,
        };
        api
          .post("/api/update-cart", data)
          .then((response) => {
            console.log(response);
            resolve();
          })
          .catch((error) => {
            console.log(error.response);
            reject();
          });
      });
    },
    SENDCARTORDER({ commit, state }, order) {
      return new Promise((resolve, reject) => {
        console.log(order, state.user);
        const data = {
          // ...state.user,
          order,
        };
        api
          .post("/api/email?_csrf=" + order.csrf, data)
          .then((response) => {
            console.log("SUCCESS!!");
            commit("saveUserInStore", response.data);
            resolve("SUCCESS!!");
          })
          .catch(() => {
            log("Store:#0f0", "SENDCARTORDER FAILURE!!");
            reject("FAILURE!!");
          });
      });
    },

    SENDNOTIFICATION({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .post("/api/message-user", data)
          .then((response) => {
            commit("saveUserInStore", response.data);
            resolve("SUCCESS!!");
          })
          .catch(() => {
            log("Store:#0f0", "SENDNOTIFICATION FAILURE!!");
            reject("FAILURE!!");
          });
      });
    },

    UPDATEUNREAD({ commit, state }, noteUpdate) {
      return new Promise((resolve, reject) => {
        let data = {
          _id: state.user._id,
          _csrf: state.csrf,
          ...noteUpdate,
        };
        log("Store:#0f0", "Update read", data);
        api
          .post("/api/update-message", data)
          .then((response) => {
            log("Store:#0f0", "Update Response", response.data);
            commit("saveUserInStore", response.data);
            resolve("SUCCESS!!");
          })
          .catch(() => {
            console.log("FAILURE!!");
            reject("FAILURE!!");
          });
      });
    },

    DELETENOTIFICATION({ commit, state }, noteDelete) {
      return new Promise((resolve, reject) => {
        let data = {
          _id: state.user._id,
          _csrf: state.csrf,
          ...noteDelete,
        };
        api
          .post("/api/delete-notification", data)
          .then((response) => {
            log("Store:#0f0", "Delete Resposnse", response.data);
            commit("saveUserInStore", response.data);
            resolve("SUCCESS!!");
          })
          .catch(() => {
            console.log("FAILURE!!");
            reject("FAILURE!!");
          });
      });
    },

    REGISTERNEWUSER({ state }, newUser) {
      return new Promise((resolve, reject) => {
        console.log(state.user);
        api
          .post("/api/register", newUser)
          .then((response) => {
            log("Store:#0f0", response.data);
            // commit("saveUserInStore", response.data);
            resolve("SUCCESS!!");
          })
          .catch((error) => {
            // console.log("FAILURE!!", error);
            reject(error);
          });
      });
    },

    GETCUSTOMERLIST({ commit, state }) {
      return new Promise((resolve, reject) => {
        let data = {
          ...state.user,
          _csrf: state.csrf,
        };
        api
          .post("/api/customers", data)
          .then((response) => {
            log("Store:#0f0", "", response.data);
            commit("saveCustomersInStore", response.data);
            resolve(200);
          })
          .catch((error) => {
            console.log("FAILURE!!", error.data);
            reject(401);
          });
      });
    },

    GETADMINMANAGER({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .get("/api/user-admin")
          .then((response) => {
            log("Store:#0f0", "", response.data);
            commit("saveAdminInStore", response.data.admin);
            resolve(200);
          })
          .catch((error) => {
            console.log("FAILURE!!", error.data);
            reject(401);
          });
      });
    },

    UPDATEORDERSTATUS({ commit, state }, update) {
      return new Promise((resolve, reject) => {
        let data = {
          ...update,
          _csrf: state.csrf,
          _id: state.user._id,
        };
        // log("Store:#0f0", "Data", data);
        api
          .post("/api/update-status", data)
          .then((response) => {
            log("Store:#0f0", "Update status", response.data);
            commit("saveUserInStore", response.data);
            resolve(200);
          })
          .catch((error) => {
            console.log("FAILURE!!", error.data);
            reject(401);
          });
      });
    },

    UPDATESINGLEPRODUCT({ state }, update) {
      return new Promise((resolve, reject) => {
        let data = {
          ...update,
          _csrf: state.csrf,
        };
        api
          .post("/api/update-product", data)
          .then((response) => {
            log("Store:#0f0", "Update product", response.data);
            resolve(200);
          })
          .catch((error) => {
            console.log("FAILURE!!", error.data);
            reject(401);
          });
      });
    },
    DELETESINGLEPRODUCT({ state }, prod) {
      return new Promise((resolve, reject) => {
        let data = {
          _id: prod._id,
          _csrf: state.csrf,
        };
        api
          .post("/api/delete-product", data)
          .then((response) => {
            log("Store:#0f0", "Delete product", response.data);
            resolve(200);
          })
          .catch((error) => {
            console.log("FAILURE!!", error.data);
            reject(401);
          });
      });
    },

    UPLOADIMAGES({ state }, files) {
      return new Promise((resolve, reject) => {
        console.log(state.csrf);
        api
          .files("/api/upload-images", files, state.csrf)
          .then((response) => {
            log("Store:#0f0", "Upload images", response);
            resolve(200);
          })
          .catch((error) => {
            console.log("FAILURE UPLOADING!!", error);
            reject(401);
          });
      });
    },
  },
});
